export default class ExpandedText {
  init() {
    window.addEventListener("DOMContentLoaded", (event) => {
      const expandableTextElements = document.querySelectorAll(".expand-text");
      const maxLength = 100; // Maximum number of characters to display
      const isGreek = document.documentElement.lang === "el"; // Check the language of the document
      // Translations for the button texts
      const translations = {
        readMore: {
          en: "Read More",
          el: "Διαβάστε περισσότερα",
        },
        readLess: {
          en: "Read Less",
          el: "Διαβάστε λιγότερα",
        },
      };
      expandableTextElements.forEach((element) => {
        const htmlContent = element.innerHTML;
        const readMoreButton = element.nextElementSibling;
        if (htmlContent.length > maxLength) {
          const truncatedHtmlContent = htmlContent.slice(0, maxLength) + "...";
          element.innerHTML = truncatedHtmlContent;
          readMoreButton.textContent =
            translations.readMore[isGreek ? "el" : "en"];
          readMoreButton.addEventListener("click", () => {
            if (element.innerHTML === htmlContent) {
              element.innerHTML = truncatedHtmlContent;
              readMoreButton.textContent =
                translations.readMore[isGreek ? "el" : "en"];
            } else {
              element.innerHTML = htmlContent;
              readMoreButton.textContent =
                translations.readLess[isGreek ? "el" : "en"];
            }
          });
        } else {
          readMoreButton.style.display = "none"; // Hide the button
        }
      });
    });
  }
}
